@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

body{
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 30px;
  height: 90vh;
}


#header{
  width: 100%;
  height: 8vh;
  max-height: 8vh;
  background-color: #fafafa;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin: 0;
  padding-top: 0;
  padding-left: 2vw;
  padding-right: 2vw;
   border-bottom: gray;
  border-bottom-width: 0.1px;
  border-bottom-style: solid; 
  box-shadow: 0px 7px 5px -7px gray;  
  top:0;
  left: 0;
  z-index: 999;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

#footer{
  height: 8vh;
  position: fixed;
  bottom: 0 !important;
  left: 0 !important;
  width: 100%;
  max-height: 8vh;
  background-color: #000022;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-top: 0;
  padding-left: 2vw;
  padding-right: 2vw;

}

.col{
  margin: 0;
  padding: 0;
  text-align: center;
}

#popupBox{
  position: absolute;
  top: 25%;
  left: 15%;
  width: 70%;
  height: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  padding: 20px;
  overflow-wrap: break-word;
  word-wrap: normal;
  z-index: 99999;
}
